<template>
  <div class="mb-5">
    <v-row no-gutters>
      <v-card
        class="rounded-lg pa-4"
        :class="!darkmode || 'dark-mode'"
        flat
        width="100%"
      >
        <v-row no-gutters justify="center">
          <v-col cols="6" sm="4" md="2" class="divider pl-2">
            <div class="font-weight-light">{{ $t("app.subjects") }}</div>
            <div class="font-weight-bold">{{ info.header.lesson_name }}</div>
          </v-col>
          <v-col cols="6" sm="4" md="1" class="divider pl-2">
            <div class="font-weight-light">{{ $t("app.grade") }}</div>
            <div class="font-weight-bold">{{ info.header.grade_name }}</div>
          </v-col>
          <v-col cols="6" sm="4" md="1" class="divider pl-2">
            <div class="font-weight-light">{{ $t("app.class") }}</div>
            <div class="font-weight-bold">{{ info.header.class_name }}</div>
          </v-col>
          <v-col cols="6" sm="4" md="2" class="divider pl-2">
            <div class="font-weight-light">{{ getText().title }}</div>
            <div class="font-weight-bold">{{ getText().value }}</div>
          </v-col>
          <v-col cols="6" sm="4" md="2" class="divider pl-2">
            <div class="font-weight-light">{{ $t("app.school_year") }}</div>
            <div class="font-weight-bold">
              {{ info.header.start_year }} - {{ info.header.end_year }}
            </div>
          </v-col>
          <v-col
            cols="6"
            sm="4"
            md="1"
            class="pl-2"
            :class="!title || 'divider'"
          >
            <div class="font-weight-light">Semester</div>
            <div class="font-weight-bold">{{ info.header.semester }}</div>
          </v-col>
          <v-col cols="6" sm="4" md="3" class="pl-2" v-if="title">
            <div class="font-weight-light">
              {{ $t("e_test.table_header.detail_subjects.title") }}
            </div>
            <div class="font-weight-bold">{{ info.header.title }}</div>
          </v-col>
          <v-col cols="6" sm="4" md="3" class="pl-2" v-if="chance">
            <div class="font-weight-light">
              {{ $t("e_test.chance") }}
            </div>
            <div class="font-weight-bold">{{ info.header.max_chance }}</div>
          </v-col>
          <v-col cols="12" sm="12" md="3" v-if="buttonName">
            <v-row
              no-gutters
              :class="$vuetify.breakpoint.smAndDown ? 'mt-3' : ''"
            >
              <v-spacer />
              <v-btn
                v-if="showButton"
                :disabled="disableBtn"
                :dark="!disableBtn"
                color="gradient-primary"
                depressed
                class="mr-1"
                @click="action"
              >
                {{ buttonName }}
                <v-icon class="pl-1">{{ icon }}</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import i18n from "@/i18n";
import store from "@/store";

export default {
  props: {
    info: Object,
    buttonName: String,
    title: Boolean,
    chance: Boolean,
    disableBtn: Boolean,
    textTotal: String,
    showTotal: {
      type: Boolean,
      default: false
    },
    etest: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: "mdi-plus-circle-outline"
    },
    showButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    darkmode() {
      return store.getters.g_darkmode;
    }
  },
  methods: {
    getText() {
      if (this.showTotal) {
        return {
          title: this.textTotal
            ? this.textTotal
            : this.etest
            ? `Total ${i18n.t("e_test.question")}`
            : "Total E-Test",
          value: this.info.body.total
        };
      } else {
        return { title: i18n.t("e_test.title"), value: this.info.header.title };
      }
    },
    action() {
      this.$emit("actionButton");
    }
  }
};
</script>
<style lang="scss" scoped>
.dark-mode {
  background: #121212;
  border: 10px solid #424242;
}
</style>
